<template>
  <div>
    <user-dates></user-dates>
  </div>
</template>

<script>
import UserDates from '@/components/UserDates.vue';

export default{
  components: {
    'user-dates': UserDates
  },
  data(){
    return{
    }
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
